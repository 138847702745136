import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Image, SupportedFileFormat } from 'cms-common'
import { Button, Col, Popover, Row, Typography } from 'antd'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { SplashScreen } from '../components/SplashScreen'
import { useCMS } from '../hooks/useCMS'
import { ImageDisplay } from '../components/ImageDisplay'
import { convertFileSize } from '../utils/convertFileSize'
import { DateFormatter } from '../components/DateFormatter'

export const ImageDetails = () => {
  const [image, setImage] = useState<Image | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const navigate = useNavigate()
  const { name } = useParams<{ name: string }>()

  const useCMSApi = useCMS()

  const getImageData = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = await useCMSApi.get(`/image/metadata/${name}.svg`)

      setImage(data)
    } catch (err) {
      if (err instanceof Error) {
        setError(err)
      }
    } finally {
      setIsLoading(false)
    }
    // Adding useCMSApi causes rerender loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  useEffect(() => {
    getImageData()
  }, [getImageData])

  const deleteImage = async (imageName: string) => {
    setIsLoading(true)

    try {
      await useCMSApi.delete(`/image/${imageName}.svg`)

      navigate('/images')
    } catch (err) {
      if (err instanceof Error) {
        setError(err)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getDisplayedExtensionFromOriginalExtension = useCallback(
    (originalExtension: SupportedFileFormat, placement: 'left' | 'right') => {
      if (placement === 'right') {
        return originalExtension === 'svg' ? 'png' : 'svg'
      }
      return ['jpg', 'jpeg', 'png'].includes(originalExtension) ? originalExtension : 'svg'
    },
    [],
  )

  if (isLoading) {
    return <SplashScreen />
  }

  if (error) {
    throw error
  }

  return (
    <div className="container" style={{ width: '100%' }}>
      {image ? (
        <>
          <Row justify="space-between">
            <Col>
              <Typography.Title level={1} style={{ marginBottom: 50 }}>
                {`Image Details - ${image.originalFileName}.${image.originalExtension}`}
              </Typography.Title>
            </Col>
            <Col>
              <Button
                type="primary"
                danger
                onClick={() =>
                  confirm('Are you sure you want to delete this image?') && deleteImage(image.generatedFileName)
                }
              >
                Delete Image
              </Button>
            </Col>
          </Row>

          <div style={{ width: '100%' }}>
            <Row justify="space-around">
              {(['left', 'right'] as const).map((placement) => {
                const displayedExtension = getDisplayedExtensionFromOriginalExtension(
                  image.originalExtension,
                  placement,
                )
                return (
                  <Popover
                    key={placement}
                    placement="bottom"
                    content={
                      <ImageDisplay
                        href={image.privateUrl}
                        dimensions={image.dimensions}
                        extension={displayedExtension}
                      />
                    }
                  >
                    <Col>
                      <ImageDisplay
                        href={image.privateUrl}
                        dimensions={{ width: 250 }}
                        extension={displayedExtension}
                      />
                      <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{displayedExtension.toUpperCase()}</p>
                    </Col>
                  </Popover>
                )
              })}
            </Row>

            <div style={{ marginTop: 50 }}>
              <p>
                <strong>Original Name:</strong> {image.originalFileName}
              </p>
              <p>
                <strong>Generated Name:</strong> {image.generatedFileName}
              </p>
              <p>
                <strong>Original Extension:</strong> {image.originalExtension}
              </p>
              <p>
                <strong>Access: </strong>
                {image.isProtected ? (
                  <span style={{ color: 'red' }}>
                    Private <LockOutlined />
                  </span>
                ) : (
                  <span style={{ color: 'green' }}>
                    Public <UnlockOutlined />
                  </span>
                )}
              </p>
              <p>
                <strong>Private Url:</strong> {image.privateUrl}
              </p>
              {image.publicUrl ? (
                <p>
                  <strong>Public Url:</strong> {image.publicUrl}
                </p>
              ) : null}
              <p>
                <strong>Size:</strong> {convertFileSize(image.fileSize)}
              </p>
              <p>
                <strong>Dimensions:</strong> {image.dimensions.width}x{image.dimensions.height}
              </p>
              <p>
                <strong>Created At:</strong> <DateFormatter date={image.createdAt} />
              </p>
            </div>
          </div>
        </>
      ) : (
        <SplashScreen />
      )}
    </div>
  )
}
