import React, { FC } from 'react'
import { UserContext } from './context/authContext'
import { useOAuthLogin } from './hooks/useOAuthLogin'
import { AppRoutes } from './Routes'

/**
 * Main app entry point component
 *
 * @returns The generated JSX
 */
export const App: FC = () => {
  const { user } = useOAuthLogin()

  return (
    <UserContext.Provider value={{ user }}>
      <AppRoutes />
    </UserContext.Provider>
  )
}
