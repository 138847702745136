export const convertFileSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB']

  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(1024))

  if (sizeIndex === 0) {
    return `${bytes} ${sizes[sizeIndex]}`
  }

  return `${(bytes / Math.pow(1024, sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`
}
