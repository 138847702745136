import { SupportedFileFormat } from 'cms-common'
import React, { FC, useMemo } from 'react'
import AlphaBackground from '../assets/images/alpha.png'

export const ImageDisplay: FC<{
  href: string
  dimensions: { width: number; height?: number }
  extension?: SupportedFileFormat | ''
}> = ({ href, dimensions: { width }, extension = '' }) => {
  const extensionSuffix = useMemo(() => (extension ? `.${extension}` : ''), [extension])

  return (
    <img
      src={`${href}${extensionSuffix}`}
      style={{ width: width <= 900 ? width : 900, background: `url(${AlphaBackground}) no-repeat center center/cover` }}
    />
  )
}
