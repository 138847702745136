import React, { FC, useState } from 'react'
import { Button, Form, Input, Modal, notification, Tag, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useCMS } from '../hooks/useCMS'
import { ImageExistsValidationResponse, ValidationResult } from './Validation'

export const ValidateExistenceModal: FC<{
  resetModal: () => void
  getValidationResultColor: (result: ValidationResult) => 'green' | 'red'
}> = (props) => {
  const { resetModal, getValidationResultColor } = props

  const [imagesToValidate, setImagesToValidate] = useState('')
  const [validationResult, setValidationResult] = useState<ImageExistsValidationResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const useCMSApi = useCMS()

  const imagesArray = imagesToValidate.trim().split(/,\n|, /)

  const validateImageExists = async () => {
    setIsLoading(true)

    try {
      const { data } = await useCMSApi.post(`/validate/imageExistence`, {
        imageNames: imagesArray.map((item) => item.trim().replace('\n', '')),
      })

      setValidationResult(data)

      if (data.validationResult === 'FAILED') {
        notification.error({ message: 'Validation failed, see marked items below' })
      } else {
        notification.success({ message: 'Validation successful!' })
      }
    } catch (err) {
      notification.error({
        message: (err as AxiosError<{ details?: { description?: string } }>).response?.data?.details?.description,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal visible={true} title="Validate Image Existence" onOk={resetModal} onCancel={resetModal}>
      <Form onFinish={validateImageExists}>
        <>
          {validationResult ? (
            <>
              <Typography.Title level={5}>
                Result:{' '}
                <span style={{ color: getValidationResultColor(validationResult.validationResult) }}>
                  {validationResult.validationResult}
                </span>
              </Typography.Title>
            </>
          ) : null}
          <label>Images to validate (separate values by a comma and a space):</label>
          <div style={{ display: 'block' }}>
            {imagesArray.map((image, index) => {
              const imageNotFound = (validationResult as ImageExistsValidationResponse)?.notFoundImageNames?.includes(
                image.trim(),
              )

              if (image) {
                return (
                  <Tag
                    key={index}
                    color={imageNotFound ? 'red' : 'default'}
                    style={{
                      display: 'block',
                      cursor: 'pointer',
                      width: 'fit-content',
                      margin: '5px 0',
                    }}
                  >
                    {!imageNotFound ? <Link to={`/images/${image.split('.')[0]}`}>{image}</Link> : image}
                  </Tag>
                )
              } else {
                return null
              }
            })}
          </div>
          <Form.Item name="fileName" rules={[{ required: true, message: 'Please provide at least one file name' }]}>
            <Input.TextArea rows={10} value={imagesToValidate} onChange={(e) => setImagesToValidate(e.target.value)} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading} style={{ marginTop: 10 }}>
              Validate Image Existence
            </Button>
          </Form.Item>
        </>
      </Form>
    </Modal>
  )
}
