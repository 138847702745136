import React, { useState } from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { ValidateExistenceModal } from './ValidateExistenceModal'
import { ValidateFileSystemIntegrityModal } from './ValidateFileSystemIntegrityModal'

export type ValidationResult = 'PASSED' | 'FAILED'

export type ImageExistsValidationResponse = {
  validationResult: ValidationResult
  notFoundImageNames: string[]
}

export type FileSystemIntegrityValidationResponse = {
  validationResult: ValidationResult
  dbRecordsWithoutFile: string[]
  filesWithoutDbRecord: string[]
  invalidDbRecords: Array<{ recordId: string; problem: string }>
}

export const Validation = () => {
  const [typeOfValidation, setTypeOfValidation] = useState<'existence' | 'FSIntegrity' | ''>('')

  const getValidationResultColor = (result: ValidationResult): 'green' | 'red' =>
    result === 'PASSED' ? 'green' : 'red'

  const resetModal = () => {
    setTypeOfValidation('')
  }

  return (
    <div className="container" style={{ width: '100%' }}>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={1}>Validation</Typography.Title>
        </Col>
      </Row>
      <Row style={{ marginTop: 100 }}>
        <Col>
          <p style={{ marginBottom: 5 }}>
            Check the names of images to see whether they can be found in the file system
          </p>
          <Button type="primary" style={{ marginTop: 5 }} onClick={() => setTypeOfValidation('existence')}>
            Validate Image Existence
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 50 }}>
        <Col>
          <p style={{ marginBottom: 5 }}>
            Validate whether there are any anomalies between database entries and existing image files
          </p>
          <Button type="primary" onClick={() => setTypeOfValidation('FSIntegrity')}>
            Validate File System Integrity
          </Button>
        </Col>
      </Row>

      {typeOfValidation === 'existence' ? (
        <ValidateExistenceModal getValidationResultColor={getValidationResultColor} resetModal={resetModal} />
      ) : null}

      {typeOfValidation === 'FSIntegrity' ? (
        <ValidateFileSystemIntegrityModal getValidationResultColor={getValidationResultColor} resetModal={resetModal} />
      ) : null}
    </div>
  )
}
