import React, { FC, useCallback, useEffect, useState } from 'react'
import { Modal, Typography } from 'antd'
import { useCMS } from '../hooks/useCMS'
import { DataList } from '../components/DataList'
import { FileSystemIntegrityValidationResponse, ValidationResult } from './Validation'

export const ValidateFileSystemIntegrityModal: FC<{
  getValidationResultColor: (result: ValidationResult) => 'green' | 'red'
  resetModal: () => void
}> = (props) => {
  const { resetModal, getValidationResultColor } = props
  const [validationResult, setValidationResult] = useState<FileSystemIntegrityValidationResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const useCMSApi = useCMS()

  const validateFileSystemIntegrity = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = await useCMSApi.get(`/validate/databaseFilesystemIntegrity`)

      setValidationResult(data)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
    // adding useCMSApi causes rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    validateFileSystemIntegrity()
  }, [validateFileSystemIntegrity])

  if (error) {
    throw error
  }

  const dbRecordsWithNpFile = validationResult ? validationResult.dbRecordsWithoutFile : []
  const filesWithNoDBRecord = validationResult ? validationResult.filesWithoutDbRecord : []
  const invalidRecords = validationResult ? validationResult.invalidDbRecords : []

  return (
    <Modal
      visible={true}
      title="Validate File System Integrity"
      confirmLoading={isLoading}
      onOk={resetModal}
      onCancel={resetModal}
    >
      <>
        {validationResult ? (
          <>
            <Typography.Title level={5}>
              Result:{' '}
              <span style={{ color: getValidationResultColor(validationResult.validationResult) }}>
                {validationResult.validationResult}
              </span>
            </Typography.Title>
          </>
        ) : null}
        <DataList resource={dbRecordsWithNpFile} label={'Database records without files:'} isLink />

        <DataList resource={filesWithNoDBRecord} label={'Files without database records:'} shortTitle />

        <DataList resource={invalidRecords} label={'Invalid database records:'} />
      </>
    </Modal>
  )
}
