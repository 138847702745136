import { useDebouncedState } from 'xund-react-utils'
import { Select } from 'antd'
import { Image } from 'cms-common'
import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useCMS } from '../hooks/useCMS'
import { searchImages } from '../utils/searchImages'

type EventDataType = 'IMAGE_SELECTED'

type WindowParentPostMessage = {
  type: EventDataType
  image?: string
}

const pageSize = 3
const page = 1
const debounceTimeout = 200

export const ImageSelector: FC = () => {
  const [images, setImages] = useState<Image[]>([])
  const CMSApi = useCMS()
  const [error, setError] = useState<Error>()
  const { setValue: setSearchText, debouncedValue: searchText } = useDebouncedState('', debounceTimeout)
  const initialSelection = new URLSearchParams(useLocation().search).get('initialSelection') ?? undefined

  useEffect(() => {
    searchImages({
      optionsSetter: setImages,
      errorSetter: setError,
      text: searchText,
      CMSApi,
      page,
      pageSize,
    })
  }, [searchText, CMSApi])

  if (error) {
    throw error
  }

  return (
    <div
      style={{
        background: '#F0F2F5',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
      }}
    >
      <Select
        showSearch
        defaultValue={initialSelection}
        style={{ width: '100%' }}
        onSearch={setSearchText}
        filterOption={false}
        onChange={(value) => {
          const postMessagePayload: WindowParentPostMessage = { type: 'IMAGE_SELECTED', image: value }
          xundEnvironment.POST_MESSAGE_ORIGIN_WHITELIST.split(',').forEach((url) => {
            window.parent.postMessage(postMessagePayload, url)
          })
        }}
      >
        {images.map((image) => (
          <Select.Option key={image._id} value={image.generatedFileName}>
            <img
              src={`${xundEnvironment.CMS_BACKEND_BASE_URL}/image/${image.generatedFileName}`}
              style={{ height: '16px' }}
            />{' '}
            - {image.originalFileName} - {image.generatedFileName}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}
