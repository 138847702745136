import React, { FC } from 'react'

export const DateFormatter: FC<{ date: Date }> = (props) => {
  return (
    <span>
      {new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      })
        .format(new Date(props.date))
        .replace(',', '')}
    </span>
  )
}
