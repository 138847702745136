import { AxiosInstance } from 'axios'
import { Image } from 'cms-common'

/**
 *
 * The method that filters the images
 *
 * @param param0 Params object
 * @param param0.optionsSetter The options setter method
 * @param param0.countSetter The count setter method
 * @param param0.errorSetter The error setter method
 * @param param0.CMSApi Axios instance to call the API
 * @param param0.text The search text
 * @param param0.page The current page for pagination
 * @param param0.pageSize The current page size for pagination
 */
export const searchImages = async ({
  optionsSetter,
  countSetter,
  errorSetter,
  CMSApi,
  text,
  page = 1,
  pageSize = 10,
}: {
  CMSApi: AxiosInstance
  optionsSetter: (param: Image[]) => void
  errorSetter?: (param: Error) => void
  countSetter?: (param: number) => void
  text?: string
  page?: number
  pageSize?: number
}) => {
  try {
    const pagination = { top: pageSize, skip: (page - 1) * pageSize }
    const { data } = await CMSApi.get(`/image`, {
      params: text ? { optionSearchTerm: text, ...pagination } : pagination,
    })

    optionsSetter(data.images)
    countSetter?.(data.count)
  } catch (err) {
    if (err instanceof Error) {
      errorSetter?.(err)
    }
  }
}
