import React, { Component, ErrorInfo } from 'react'
import { Result, Typography } from 'antd'

export interface ErrorResponseType {
  message?: string
  response?: {
    data: {
      customType: string
      details?: {
        errorCode?: string
        description?: string
      }
      error: string
    }
    status: string
  }
}

export interface ErrorBoundaryState {
  error: ErrorResponseType
  hasError: boolean
}

/**
 * The ErrorBoundary class
 */
export class ErrorBoundary extends Component<{ children: JSX.Element }, ErrorBoundaryState> {
  /**
   * The ErrorBoundary constructor
   *
   * @param props The props object
   * @param props.children The children
   */
  constructor(props: { children: JSX.Element }) {
    super(props)
    this.state = {
      error: this.state?.error,
      hasError: this.state?.error !== undefined,
    }
  }

  /**
   * The getDerivedStateFromError method
   *
   * @param error The error object
   * @returns The hasError boolean
   */
  public static getDerivedStateFromError(error: Error) {
    return { error, hasError: true }
  }

  /**
   * The componentDidCatch method
   *
   * @param error The error object
   * @param errorInfo The errorInfo object
   */
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error })
    // eslint-disable-next-line no-console
    console.error(error, errorInfo)
  }

  /**
   * The render method
   *
   * @returns The ErrorModal component if there is an error, otherwise props.children
   */
  public render() {
    if (this.state.hasError) {
      if (this.state.error.response?.data?.customType || this.state.error.response?.data?.details?.errorCode) {
        return (
          <Result
            status="error"
            title="Error"
            subTitle={
              <Typography style={{ width: 400, margin: 'auto' }}>{this.state.error.response.data.error}</Typography>
            }
            extra={
              <>
                <Typography style={{ width: 400, margin: 'auto' }}>
                  {this.state.error.response.data.customType
                    ? this.state.error.response.data.customType
                    : this.state.error.response.data.details?.errorCode}
                </Typography>
                <Typography style={{ width: 400, margin: 'auto' }}>
                  {this.state.error.response.data.details?.description}
                </Typography>
              </>
            }
            style={{ marginTop: 200 }}
          />
        )
      } else if (this.state.error.message) {
        return (
          <Result
            status="error"
            title="Error"
            subTitle={<Typography style={{ width: 400, margin: 'auto' }}>{this.state.error.message}</Typography>}
            style={{ marginTop: 200 }}
          />
        )
      }
    }

    return this.props.children
  }
}
