import React from 'react'
import { Spin } from 'antd'

export const SplashScreen = () => {
  return (
    <div className="container" style={{ marginTop: 'auto' }}>
      <div className="spinner">
        <Spin />
      </div>
    </div>
  )
}
