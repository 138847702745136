import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Popover, Row, Table, Typography } from 'antd'
import { Image } from 'cms-common'
import { useDebouncedState } from 'xund-react-utils'
import { Link } from 'react-router-dom'
import { DeleteOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { searchImages } from '../utils/searchImages'
import { DateFormatter } from '../components/DateFormatter'
import { useCMS } from '../hooks/useCMS'
import { ImageDisplay } from '../components/ImageDisplay'
import { UploadNewImageModal } from '../components/UploadNewImageModal'

export const Images = () => {
  const [images, setImages] = useState<Image[]>([])
  const { setValue: setSearchText, debouncedValue: searchText } = useDebouncedState('', 200)
  const [isAddNewModalVisible, setIsAddNewModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [count, setCount] = useState(1)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [error, setError] = useState<Error>()

  const useCMSApi = useCMS()

  useEffect(() => {
    searchImages({
      optionsSetter: setImages,
      countSetter: setCount,
      errorSetter: setError,
      CMSApi: useCMSApi,
      text: searchText,
      page,
      pageSize,
    })
  }, [searchText, page, pageSize, useCMSApi])

  const deleteImage = async (imageName: string) => {
    setIsLoading(true)

    try {
      await useCMSApi.delete(`/image/${imageName}.svg`)

      setImages(images.filter((image) => image.generatedFileName !== imageName))
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }

  if (error) {
    throw error
  }

  return (
    <div className="container" style={{ width: '100%' }}>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={1}>Images</Typography.Title>
        </Col>
        <Col>
          <Button type="primary" loading={isLoading} onClick={() => setIsAddNewModalVisible(true)}>
            Upload New Image
          </Button>
        </Col>
      </Row>

      <Input.Search
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        enterButton
        autoFocus
        style={{ width: 'calc(100% + 1px)', margin: '30px 0 10px 0' }}
      />

      <Table
        rowKey="_id"
        loading={isLoading}
        dataSource={images}
        columns={[
          {
            title: '',
            dataIndex: '',
            key: '',
            width: 150,
            align: 'center',
            render: (_, image) => (
              <Popover
                placement="right"
                content={
                  <ImageDisplay
                    href={`${xundEnvironment.CMS_BACKEND_BASE_URL}/image/${
                      image.generatedFileName || image.originalFileName
                    }.${image.originalExtension}`}
                    dimensions={{ width: 250 }}
                  />
                }
              >
                <Link to={`/images/${image.generatedFileName || image.originalFileName}`} style={{ cursor: 'pointer' }}>
                  <ImageDisplay
                    href={`${xundEnvironment.CMS_BACKEND_BASE_URL}/image/${
                      image.generatedFileName || image.originalFileName
                    }.${image.originalExtension}`}
                    dimensions={{ width: 120 }}
                  />
                </Link>
              </Popover>
            ),
          },
          {
            title: 'Original Name',
            dataIndex: 'originalFileName',
            key: 'originalFileName',
            width: 250,
            sorter: (a, b) => a.originalFileName.localeCompare(b.originalFileName),
            render: (originalFileName) => <Link to={`/images/${originalFileName}`}>{originalFileName}</Link>,
          },
          {
            title: 'Generated Name',
            dataIndex: 'generatedFileName',
            key: 'generatedFileName',
            width: 300,
            sorter: (a, b) => a.generatedFileName.localeCompare(b.generatedFileName),
            render: (generatedFileName) => <Link to={`/images/${generatedFileName}`}>{generatedFileName}</Link>,
          },
          {
            title: 'Access',
            dataIndex: 'isProtected',
            key: 'isProtected',
            width: 100,
            align: 'center',
            sorter: (a, b) => +a.isProtected - +b.isProtected,
            render: (isProtected) => (
              <>
                {isProtected ? (
                  <span style={{ color: 'red' }}>
                    Private <LockOutlined />
                  </span>
                ) : (
                  <span style={{ color: 'green' }}>
                    Public <UnlockOutlined />
                  </span>
                )}
              </>
            ),
          },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            align: 'center',
            sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            render: (createdAt) => <DateFormatter date={createdAt} />,
          },
          {
            title: '',
            dataIndex: '',
            key: '',
            width: 100,
            align: 'center',
            render: (_, image) => (
              <Button
                type="primary"
                loading={isLoading}
                danger
                onClick={() =>
                  confirm('Are you sure you want to delete this image?') && deleteImage(image.generatedFileName)
                }
              >
                <DeleteOutlined />
              </Button>
            ),
          },
        ]}
        pagination={{
          pageSize,
          onShowSizeChange: (_current, size) => setPageSize(size),
          current: page,
          onChange: (pageNum) => setPage(pageNum),
          total: count,
        }}
      />

      <UploadNewImageModal isVisible={isAddNewModalVisible} hideModal={() => setIsAddNewModalVisible(false)} />
    </div>
  )
}
