import { CarryOutOutlined, PictureOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const menuItems: ItemType[] = [
  { label: 'Images', icon: <PictureOutlined />, key: '/images' },
  { label: 'Validation', icon: <CarryOutOutlined />, key: '/validation' },
]

export const MainFrame: FC<{ children: JSX.Element }> = ({ children }) => {
  const location = useLocation()

  const [selectedKeys, setSelectedKeys] = useState(
    location.pathname
      .split('/')
      .filter((v) => v.length)
      .reduce((prev: string[], current) => [...prev, `${prev.length ? prev[prev.length - 1] : ''}/${current}`], []),
  )

  useEffect(() => {
    setSelectedKeys(
      location.pathname
        .split('/')
        .filter((v) => v.length)
        .reduce((prev: string[], current) => [...prev, `${prev.length ? prev[prev.length - 1] : ''}/${current}`], []),
    )
  }, [location])

  return (
    <div style={{ height: '101vh', display: 'flex' }}>
      <Layout>
        <Header style={{ height: 63, background: 'white' }}>
          <Menu items={menuItems} mode="horizontal" style={{ padding: '0' }} defaultSelectedKeys={selectedKeys} />
        </Header>
        <div style={{ marginTop: 50, width: '100%', padding: 2 }}>{children}</div>
      </Layout>
    </div>
  )
}
