import { User } from 'xund-auth'
import { useCallback, useEffect, useState } from 'react'
import { CurrentUserState } from 'cms-common/dist/models/currentUserState'
import { useCMS } from './useCMS'

type UseOAuthLogin = () => {
  user: User | null
}

export const useOAuthLogin: UseOAuthLogin = () => {
  const CMSApi = useCMS()
  const [user, setUser] = useState<User | null>(null)

  const startAuthentication = useCallback(async () => {
    const { data } = await CMSApi.get<CurrentUserState>('/users/current', {})
    if (data.success) {
      console.log('success')
      return
    }

    const loginUrl = new URL(data.loginUrl)
    loginUrl.searchParams.set('redirectUrl', `${location.pathname}${location.search}`)
    window.location.replace(loginUrl.toString())
  }, [CMSApi])

  const finishAuthentication = useCallback(async () => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token') as string

    const {
      data: { user: userFromBackend },
    } = await CMSApi.post<{ user: User }>('/users/oauth-login', { token })

    setUser(userFromBackend)
    const redirectUrl = params.get('redirectUrl')
    redirectUrl && window.location.replace(redirectUrl)
  }, [CMSApi, setUser])

  useEffect(() => {
    if (location.pathname === '/images-login') {
      finishAuthentication()
      return
    }

    startAuthentication()
  }, [startAuthentication, finishAuthentication])

  return { user }
}
