import axios from 'axios'
import { useMemo } from 'react'

/**
 * The CMS axios instance factory
 *
 * @returns The axios instance to be used for the CMS
 */
export const useCMS = () => {
  return useMemo(() => {
    const axiosInstance = axios.create({
      timeout: 60000,
      baseURL: xundEnvironment.CMS_BACKEND_BASE_URL,
    })

    axiosInstance.interceptors.request.use(
      async (config) => {
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    return axiosInstance
  }, [])
}
