import { LoadingOutlined, LockOutlined, UnlockOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Modal, notification, Switch, Typography, Upload, UploadFile } from 'antd'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const UploadNewImageModal: FC<{ isVisible: boolean; hideModal: () => void }> = (props) => {
  const [image, setImage] = useState<any>({ isProtected: true })
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate()

  const handleChange = (info: { file: UploadFile }) => {
    if (info.file.status === 'uploading') {
      setIsSaving(true)
      return
    }
    if (info.file.status === 'error') {
      setIsSaving(false)
      setError(info.file.response.description)
      return
    }

    if (info.file.status === 'done') {
      setIsSaving(false)

      props.hideModal()
      notification.success({ message: 'Image uploaded!' })

      navigate(`/images/${info.file.response.fileName.replace(/(.svg|.png)/, '')}`)
    }
  }

  const closeModal = () => {
    setError('')
    props.hideModal()
  }

  return (
    <Modal title="Upload New Image" open={props.isVisible} onOk={closeModal} onCancel={closeModal} destroyOnClose>
      <div style={{ textAlign: 'center', overflow: 'hidden' }}>
        <Typography>Accepted extensions: png / svg</Typography>
        <Upload
          name="photo"
          showUploadList={false}
          action={`${xundEnvironment.CMS_BACKEND_BASE_URL}/image?isProtected=${image.isProtected}`}
          withCredentials={true}
          onChange={handleChange}
          beforeUpload={(file) => {
            const isFilePngOrSvg = file.type === 'image/png' || file.type === 'image/svg+xml'

            if (!isFilePngOrSvg) {
              setError(`The image file must have a png or a svg extension`)
            }

            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
              setError(`The image file must be smaller than 2MB`)
            }
            return isFilePngOrSvg && isLt2M
          }}
        >
          <Button
            icon={isSaving ? <LoadingOutlined /> : <UploadOutlined />}
            disabled={isSaving}
            style={{ margin: '20px 0' }}
            danger={!!error}
            loading={isSaving}
          >
            Upload an image
          </Button>
        </Upload>

        <Typography>Should the image be private? </Typography>
        <Switch
          checkedChildren={<LockOutlined />}
          unCheckedChildren={<UnlockOutlined />}
          defaultChecked
          checked={image?.isProtected}
          onClick={() => setImage({ ...image, isProtected: !image.isProtected })}
        />

        {error ? (
          <Typography style={{ color: 'red', marginTop: 10 }}>
            <strong>Error:</strong> {error}
          </Typography>
        ) : null}
      </div>
    </Modal>
  )
}
