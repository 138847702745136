import React, { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MainFrame } from './MainFrame'
import { ImageDetails } from './pages/ImageDetails'
import { Images } from './pages/Images'
import { ImageSelector } from './pages/ImageSelector'
import { Validation } from './pages/Validation'

/**
 * The routes component
 *
 * @returns The routes component which has the current routes
 */
export const AppRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route path="/images-selector" element={<ImageSelector />} />
        <Route path="/" element={<Navigate to="/images" />} />
        <Route
          path="/images"
          element={
            <MainFrame>
              <Images />
            </MainFrame>
          }
        />
        <Route path="/images-login" />
        <Route
          path="/images/:name"
          element={
            <MainFrame>
              <ImageDetails />
            </MainFrame>
          }
        />
        <Route
          path="/validation"
          element={
            <MainFrame>
              <Validation />
            </MainFrame>
          }
        />
      </Routes>
    </>
  )
}
