import React, { FC } from 'react'
import { Tag } from 'antd'
import { Link } from 'react-router-dom'

type InvalidDBRecordType = {
  recordId: string
  problem: string
}

export const DataList: FC<{
  resource: string[] | InvalidDBRecordType[]
  label: string
  shortTitle?: boolean
  isLink?: boolean
}> = (props) => {
  const { resource, label, shortTitle, isLink } = props

  const determineTitle = (image: string | InvalidDBRecordType) => {
    return shortTitle && typeof image === 'string'
      ? image
      : `${(image as InvalidDBRecordType).recordId} - ${(image as InvalidDBRecordType).problem}`
  }

  const getTitle = (image: string | InvalidDBRecordType) =>
    isLink && typeof image === 'string' ? (
      <Link to={`/images/${image.split('.')[0]}`}>{image}</Link>
    ) : (
      determineTitle(image)
    )

  return resource?.length ? (
    <>
      <label>{label}</label>
      <div style={{ display: 'block' }}>
        {resource.map((image, index) => (
          <Tag
            key={index}
            color="error"
            style={{
              display: 'block',
              width: 'fit-content',
              margin: '5px 0',
            }}
          >
            {getTitle(image)}
          </Tag>
        ))}
      </div>
    </>
  ) : null
}
